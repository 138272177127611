import styled from 'styled-components';

const StyledButton = styled.button`
  width: ${props => props.width || '100%'};
  min-height: 40px;
  max-width: ${props => props.maxWidth};
  border: none;
  border-radius: 4px;
  color: ${props =>
    props.colorOne ? props.theme[props.colorOne]() : props.theme.white()};
  border-color: ${props =>
    props.colorOne ? props.theme[props.colorOne]() : 'transparent'};
  border-width: 2px;
  border-style: solid;
  background: ${props =>
    `linear-gradient(to left, ${
      props.colorFour ? props.theme[props.colorFour]() : 'transparent'
    } 50%, ${props.theme[props.colorTwo]()} 50%)`};
  background-size: 200% 100%;
  background-position: right bottom;
  transition: 0.3s all cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.1s;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1em;
  padding: 4px 16px;
  outline: none;
  &:hover {
    background-position: left bottom;
    border-color: ${props => props.theme[props.colorTwo]()};
    color: ${props =>
      props.colorFour ? props.theme[props.colorThree]() : props.theme.white()};
  }
`;

export default StyledButton;
