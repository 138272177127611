import React, { useState } from 'react';
import styled from 'styled-components';
import LogoNoTextSVG from './LogoNoTextSVG';
import LibertyCallTextSVG from './LibertyCallTextSVG';
import SimpleButton from './styles/SimpleButton';
import HamburgerIcon from './HamburgerIcon';
import NavBar from './Navbar';
import { Link } from 'gatsby';
import TransitionLink, {
  TransitionPortal,
} from 'gatsby-plugin-transition-link';
import WipeTransition from './WipeTransition';

const StyledHeader = styled.div`
  height: ${props => props.theme.headerHeight};
  background: white;
  width: 100%;
  position: relative;
  z-index: 13;
  display: ${props => (props.navIsOpen ? 'none' : 'flex')};
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px;
  .noTextLogo {
    width: 40px;
    min-width: 40px;
    margin: 0;
    justify-self: flex-start;
  }
  .centeredContainer {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
  }
  .textHeader {
    justify-self: center;
    align-self: center;
    min-width: 110px;
  }
  .orderAndMenuContainer {
    justify-self: flex-end;
    display: flex;
    > * {
      margin-left: 1.2rem;
    }
    margin-right: 0.5rem;
  }
  @media (max-width: 400px) {
    justify-content: flex-end;
  }
  @media (max-width: 1000px) {
    .textHeader {
      display: none;
      margin: 0;
      min-width: 90px;
    }
    button {
      font-size: 12px;
    }
  }
`;

const LinksBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => {
    if (props.light) {
      return props.theme.white(0.9);
    } else return props.theme.darkgray(0.8);
  }};
  z-index: 10;
  display: ${props => (props.isVisible ? 'block' : 'none')};
`;

const NavTransitionLink = ({ left, right, to, onClick, children }) => (
  <TransitionLink
    onClick={() => onClick(left ? 'left' : 'right')}
    to={to}
    exit={{
      length: 0.8,
    }}
    entry={{
      delay: 0.8,
      length: 0.4,
    }}
  >
    {children}
  </TransitionLink>
);

const Header = props => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [isNavIconHovered, setIsNavIconHovered] = useState(false);
  const close = () => {
    setNavIsOpen(false);
  };
  const [transitionFromSide, setTransitionFromSide] = useState();

  const handleClick = side => {
    setTransitionFromSide(side);
    close();
  };

  return (
    <>
      {transitionFromSide === 'right' && (
        <TransitionPortal>
          <WipeTransition animation="startFromHalfwayRightToLeft" />
        </TransitionPortal>
      )}
      {transitionFromSide === 'left' && (
        <TransitionPortal>
          <WipeTransition animation="startFromHalfwayLeftToRight" />
        </TransitionPortal>
      )}
      {!props.noHeader && (
        <StyledHeader id="top" navIsOpen={navIsOpen}>
          <LogoNoTextSVG tight />
          <div className="centeredContainer" style={{ zIndex: -1 }}>
            <LibertyCallTextSVG />
          </div>
          <div className="orderAndMenuContainer">
            <SimpleButton
              tight
              onClick={() =>
                window.open('https://libertycall-103670.square.site/')
              }
            >
              Buy Bottles
            </SimpleButton>
            {/* <SimpleButton
              tight
              onClick={() =>
                window.open(
                  'https://www.toasttab.com/liberty-call-distilling-1985-national-ave-1131/v3'
                )
              }
            >
              Takeout/Delivery
            </SimpleButton> */}
            <HamburgerIcon
              open={navIsOpen}
              onClick={() => setNavIsOpen(!navIsOpen)}
              onMouseOver={() => setIsNavIconHovered(true)}
              onMouseLeave={() => setIsNavIconHovered(false)}
            />
          </div>
        </StyledHeader>
      )}
      <NavBar
        getReady={isNavIconHovered}
        isOpen={navIsOpen}
        dark={props.lightBackground}
      >
        <Link to="/#home" activeClassName="active" onClick={close}>
          Home
        </Link>
        <NavTransitionLink
          to="/menu"
          activeClassName="active"
          onClick={() => handleClick('right')}
        >
          Menu
        </NavTransitionLink>
        <NavTransitionLink
          to="/spirits"
          activeClassName="active"
          onClick={() => handleClick('left')}
        >
          Spirits
        </NavTransitionLink>
        {/* <NavTransitionLink
          to="/cocktails"
          activeClassName="active"
          onClick={() => handleClick('right')}
        >
          Cocktails
        </NavTransitionLink> */}
        <NavTransitionLink
          to="/our-story"
          activeClassName="active"
          onClick={() => handleClick('right')}
        >
          Our Story
        </NavTransitionLink>
        <a
          href="https://libertycall-103670.square.site/"
          target="_blank"
          rel="noopener"
          noreferrer
          activeClassName="active"
        >
          Bottle Shop
        </a>
        <NavTransitionLink
          to="/private-label"
          activeClassName="active"
          onClick={() => handleClick('right')}
        >
          Private Label
        </NavTransitionLink>
        <NavTransitionLink
          to="/custom-bottles"
          activeClassName="active"
          onClick={() => handleClick('left')}
        >
          Custom Bottles
        </NavTransitionLink>
        <a
          href="https://tables.toasttab.com/restaurants/0525c44a-914c-4b6b-849b-64e545a0782e/findTime"
          target="_blank"
          rel="noopener"
          noreferrer
          activeClassName="active"
        >
          Reservations
        </a>
        <Link to="/#contact" activeClassName="active" onClick={close}>
          Contact
        </Link>
      </NavBar>
      <HamburgerIcon
        fixed
        white={!props.lightBackground}
        isBackgroundDark={true}
        open={navIsOpen}
        onClick={() => setNavIsOpen(!navIsOpen)}
        onMouseOver={() => setIsNavIconHovered(true)}
        onMouseLeave={() => setIsNavIconHovered(false)}
      />
      <LinksBackdrop isVisible={navIsOpen} light={props.lightBackground} />
    </>
  );
};

export default Header;
