import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Modal from './Modal';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  width: 50vw;
  .img {
    width: 50%;
  }
  .rightSide {
    width: 50%;
    padding: 16px;
  }
  h2 {
    font-size: 2rem;
    letter-spacing: 2px;
    margin: 0;
  }
  p {
    letter-spacing: 1px;
    line-height: 1.5em;
  }
  a {
    color: ${props => props.theme.red()};
    letter-spacing: 2px;
  }
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding-top: 48px;
    .rightSide {
      width: 100%;
      padding: 32px;
    }
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: -30px;
  right: -30px;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 2.5rem;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transform: scale(1, 0.8);
  height: 24px;
  width: 22px;
  &:hover {
    color: red;
  }
  transition: all 0.3s;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    color: black;
    top: 8px;
    right: 8px;
  }
`;

const SteakNightPopup = ({ onClose, imgData }) => {
  return (
    <Modal open={true} handleClose={onClose} noCloseButton={true}>
      <Container>
        <Img
          fluid={imgData.STEAK1.childImageSharp.fluid}
          alt="Steak paired with Old Fashioned Cocktail"
          objectFit="contain"
          style={{ width: '100%', maxWidth: '400px' }}
        />
        <div className="rightSide">
          <CloseButton onClick={onClose} aria-label="Close" id="close-button">
            x
          </CloseButton>
          <h2>Tuesday Night Steak Night</h2>
          <p>
            Every Tuesday Night is Steak Night from 5pm to 8pm at Liberty Call
            in Barrio Logan. Get a 12oz New York Strip served with mashed
            potatoes and an Old Fashioned cocktail. Seats fill up quickly so be
            sure to join the wait list!
          </p>
          <a
            onClick={onClose}
            href="https://tables.toasttab.com/restaurants/0525c44a-914c-4b6b-849b-64e545a0782e/joinWaitlist"
            target="_blank"
            rel="noopener"
            noreferrer
          >
            Join the Waitlist
          </a>
        </div>
      </Container>
    </Modal>
  );
};

export default SteakNightPopup;
