import React from 'react';
import styled from 'styled-components';
import StyledButton from '../styles/StyledButton';
import Logo from '../LogoNoTextSVG';

const StyledAgeGate = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.white()};
  z-index: 12;
  position: fixed;
  top: 0;
  transition: opacity 2s;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AgeGate = ({ colorOne, colorTwo, handleClick }) => {
  return (
    <StyledAgeGate colorOne={colorOne} colorTwo={colorTwo}>
      <Logo />
      <h2>Are You at Least 21 Years of Age?</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledButton
          width="180px"
          colorOne="white"
          colorTwo="white"
          colorThree="navy"
          colorFour="navy"
          onClick={handleClick}
        >
          Yes
        </StyledButton>
        <a
          style={{ width: 'min-content' }}
          href="https://hotwheels.mattel.com/shop"
        >
          <StyledButton
            width="180px"
            colorOne="white"
            colorTwo="white"
            colorThree="red"
            colorFour="red"
          >
            No
          </StyledButton>
        </a>
      </div>
    </StyledAgeGate>
  );
};

export default AgeGate;
