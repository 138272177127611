import React from 'react';
import styled from 'styled-components';

const StyledHamburger = styled.div`
  height: 40px;
  margin: 0 5px 0 10px;
  padding: 7px 0;
  width: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  cursor: pointer;
  position: ${props => (props.fixed ? 'fixed' : 'relative')};
  top: ${props => props.fixed && '14px'};
  right: ${props => props.fixed && '14px'};
  z-index: 12;
  transition: color 0.5s;

  i {
    width: 100%;
    height: 1px;
    background: ${props =>
      props.white ? props.theme.white(0.9) : props.theme.navy(0.9)};
  }
  #line1,
  #line3 {
    width: 80%;
  }

  &:hover {
    i {
      background: ${props => props.theme.red(0.9)};
    }
  }

  #line1 {
    transition: background 0.2s ease-in-out, transform 0.3s ease-in-out;
  }

  #line2 {
    transition: background 0.2s ease-in-out 0.2s, transform 0.3s ease-in-out;
  }

  #line3 {
    transition: background 0.2s ease-in-out 0.4s, transform 0.3s ease-in-out;
  }

  ${props =>
    props.open
      ? `
      transform: rotate(45deg);
      
      #line1 {
        transform: translateY(13px) rotate(180deg);
        width: 100%;
      };
      #line2 {
        opacity: 0;
      };
      #line3 { 
        transform: translateY(-12px) rotate(90deg);
        width: 100%
      };
    `
      : null};
`;

const HamburgerIcon = ({
  onClick,
  onMouseOver,
  onMouseLeave,
  open,
  fixed,
  white,
}) => {
  return (
    <StyledHamburger
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      open={open}
      onClick={onClick}
      fixed={fixed}
      white={white}
    >
      <i id="line1" />
      <i id="line2" />
      <i id="line3" />
    </StyledHamburger>
  );
};

export default HamburgerIcon;
