import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import useInterval from '../hooks/useInterval';

const logoAnimation = keyframes`
0% {
}
`;

const StyledLogo = styled.svg`
  margin: 0 auto;
  width: 30vw;
  min-width: 240px;
  path.st0 {
    fill: ${props => props.theme.navy()};
  }
  path.st1 {
    fill: ${props => props.theme.red()};
  }
  path#a {
    opacity: ${props => (props.visiblePaths.includes('a') ? 1 : 0)};
  }
  path#b {
    opacity: ${props => (props.visiblePaths.includes('b') ? 1 : 0)};
    transition: opacity 3s;
  }
  path#c {
    opacity: ${props => (props.visiblePaths.includes('c') ? 1 : 0)};
  }
  path#d {
    opacity: ${props => (props.visiblePaths.includes('d') ? 1 : 0)};
  }
  path#e {
    opacity: ${props => (props.visiblePaths.includes('e') ? 1 : 0)};
  }
  path#f {
    opacity: ${props => (props.visiblePaths.includes('f') ? 1 : 0)};
  }
  path#g {
    opacity: ${props => (props.visiblePaths.includes('g') ? 1 : 0)};
  }
  path#h {
    opacity: ${props => (props.visiblePaths.includes('h') ? 1 : 0)};
  }
  path#i {
    opacity: ${props => (props.visiblePaths.includes('i') ? 1 : 0)};
  }
  path#j {
    opacity: ${props => (props.visiblePaths.includes('j') ? 1 : 0)};
  }
  path#k {
    opacity: ${props => (props.visiblePaths.includes('k') ? 1 : 0)};
  }
  path#l {
    opacity: ${props => (props.visiblePaths.includes('l') ? 1 : 0)};
  }
  path#m {
    opacity: ${props => (props.visiblePaths.includes('m') ? 1 : 0)};
  }
  path#n {
    opacity: ${props => (props.visiblePaths.includes('n') ? 1 : 0)};
  }
  path#o {
    opacity: ${props => (props.visiblePaths.includes('o') ? 1 : 0)};
  }
  animation: ${logoAnimation} 1s ease-in-out infinite;
  path {
    transition: opacity 10ms;
  }
  @media (max-width: 400px) {
    ${props => props.tight && 'display: none;'};
  }
`;

const LogoNoTextSVG = ({ tight }) => {
  const [visiblePaths, setVisiblePaths] = useState([]);
  const [nextLetterIndex, setNextLetterIndex] = useState(0);
  const [intervalId, setIntervalId] = useState();
  const letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
  ];

  useInterval(
    () => {
      if (!visiblePaths) return;
      const newVisiblePaths = [...visiblePaths];
      newVisiblePaths.push(letters[nextLetterIndex]);
      setVisiblePaths(newVisiblePaths);
      setNextLetterIndex(nextLetterIndex + 1);
      if (nextLetterIndex === letters.length - 1) clearInterval(intervalId);
    },
    id => setIntervalId(id),
    25
  );

  // reverseAnimation &&
  //   useInterval(
  //     () => {
  //       setVisiblePaths(visiblePaths.slice(0, visiblePaths.length - 1));
  //     },
  //     id => setIntervalId(id),
  //     25
  //   );

  return (
    <StyledLogo
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      visiblePaths={visiblePaths}
      className="noTextLogo"
      tight={tight}
    >
      <title>LCDC Logo</title>
      <g id="Navy">
        <g>
          <path
            className="st0"
            id="a"
            d="M126.6,32.2c0.4-0.7-0.4-7-0.8-5.6c-0.3,1,1,3.4-1,5c-2.2,1.7-11.2,2.8-10.6,4.1
			C114.8,36.7,125.6,34.1,126.6,32.2z"
          />
          <path
            className="st0"
            id="b"
            d="M150.3,71.1c-0.7-0.6-0.9-0.5-0.9-0.5c0,0-0.1,0.1-0.1,0.2l-1.8,15.3l-1.1-0.1l0.1,0.2
			c-1.2-1.4-20.3-3.7-37.7-5.2l-0.5,0l-0.2-5.1l0.5-0.1c0.3,0,1.1-0.1,2.4-0.1c22.2-0.8,26.9-2.9,26.9-4.6c0-0.7-0.4-1.9-0.9-3.4
			c-1.1-3.4-2.7-8.5-2.3-14.5c-0.7-1.4-14.7-4.2-27.8-6.1l-0.5-0.1l0.1-7.4l0.7,0.2c2.4,0.5,20.8-4.1,22.3-5.7
			c0-3-1.6-11.3-1.9-12.5c-2.2-2.6-4.8-3.9-7.5-3.8c-2.3,0.1-4,1.3-4.1,1.6c-0.5,1-0.7,2,1,3.1l0.2,0.1l0,0.2c0.2,1,0.3,2.3-0.3,2.7
			c-0.6,0.5-2.1,0.3-3,0l-0.4-0.1l0-0.4c0.3-7.2-6.1-13.4-14.2-13.7c-4.5-0.2-9,1.5-11.9,4.4c-2.3,2.3-3.5,5.1-3.4,8
			c0,0.6,0.3,2.4,0.3,2.4l0.1,0.6l-0.6,0c-0.1,0-6.5,0.1-12.6,5.3c-0.5,1.1-1.7,12.2-1.5,16.3c0.2,2.5,8.8,6.7,14.2,8.8l1.6,0.6
			l-1.6,0.5c-7.2,2-13.1,2.1-18.4,2.1C59.3,60.6,54,60.6,48,63.7c-0.2,0.1-20.4,12.2-27.1,54.2c1.3,0,6.6-1.5,11.1-3.1l0.5-0.2
			l0.2,0.5c6.1,14.9,20,28.4,41.2,40.2c11.6,6.4,19.4,19.5,23.2,25.8c0.7,1.2,1.2,2.1,1.6,2.7c0.3,0.4,0.9,0.9,1.2,1.1
			c6.6-13.5,19.5-21.8,31.9-29.8c14.6-9.4,28.4-18.3,32.1-34.8l0.1-0.6l0.6,0.2c0,0,2.9,0.9,8.1,2.7
			C171.6,100.7,163.6,82.5,150.3,71.1z M117,20.7c0.7-2.8,8.3-1.1,8.7,1.2c0.4,2.1-3.4,2.3-3.4,2.3s-0.3-1.6-0.8-2
			C120.5,21.5,116.7,21.9,117,20.7z M121.3,24.4l-3.2,0.6l0.1-2l2.4,0.1L121.3,24.4z M108.1,22.1c-0.1-0.4-0.1-0.4-0.1-0.4
			c1.3,1,0.8,4.8-0.3,5.3C107.7,27.1,108.8,24.4,108.1,22.1z M106.7,36.2c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
			c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.3-0.1,0.5-0.3,0.8-0.5c0.1-0.1,0.2-0.1,0.2-0.2
			c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.3-0.3,0.5-0.5
			c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.4-0.4,0.6-0.6c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.4-0.5c0.1-0.1,0.1-0.1,0.2-0.2
			c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.4,0.3-0.6
			c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2
			c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.2-0.6,0.2-0.6c10.1,0.3,13.3-3.1,13.3-3.1
			s1.8,8.7,1.1,9c-4.1,2-18.3,6.4-21.2,5.4C106.8,38.6,106.6,37.1,106.7,36.2z M131.5,71.2c-1.6,3.8-23.1,2.9-23.1,2.9l0.4-9.2
			C108.9,64.8,132.4,69.3,131.5,71.2z M99,21.5c3.1,0.1,3,2.9,3,2.9l-6.2-0.3c0,0-0.1,0-0.1-0.1C96,23.7,96.9,21.4,99,21.5z
			 M96,28.1l2,0.1l-1,16.7l-1.7-0.3L96,28.1z M87.2,21.2c0,0,0.8,8.1,5.5,9.9c0.9,0.4,0.6,1.3,0.6,1.3
			C87.6,30.9,86.4,22.8,87.2,21.2z M92.7,44.6l-7.7-2.3l1.2-0.6c0.1-0.1,3.3-1.7,5.8-1.6l0.5,0L92.7,44.6z M71.8,34.3
			c1.1-4.3,12.9-5.9,12.9-5.9s1.8,4.2,7.1,6.3l-0.2,4.1c-2.1-0.1-4.9,1.1-7.6,2.7C84.1,41.4,71.2,36.7,71.8,34.3z M73.1,49.1
			c-2-2-0.6-11.2-0.6-11.2s10.1,9.3,55.9,14.9c1.5,0.2,4.7-0.2,4.8,0.6c0.3,10.4,1.6,14.8,1.8,15.5c-1.2-0.6-12.9-4.2-18-5.1
			C90.4,58.9,78.1,54.1,73.1,49.1z M98.1,61.8L97.5,80l-1.3-0.1L97,61.5L98.1,61.8z M95.6,61.3l-0.5,18.6l-1.6-0.1L94.3,61
			L95.6,61.3z M68.9,61.1c1.7,0,3.2-0.1,4.6-0.2c9.7-0.6,12.2-2.6,12.2-2.6l5.6,1.7l-0.6,13.6c-17.5,0.4-21.4,3.3-23.6,2.9
			c-4.7-0.8-11.6-1.7-11.9-2.5c-1.1-3.6-0.1-7.2,0.3-8.3c0.4-1.2,28.5-3.6,27-4c-1.7-0.5-5.1-0.4-9.1-0.1C68.7,62,63,62.7,58.5,63.4
			c-4.8,0.7-7,2.8-7,2.6C51.7,61.5,64.4,61.2,68.9,61.1z M90.5,76.8l-0.1,2.9L76,78.4C76.6,77.6,90.5,76.8,90.5,76.8z M23.6,112.2
			c0.7-9.9,9.3-30.7,19.2-38.4c0.4-0.3,0.4,1.8,0.4,1.8s-5.2,3.2-5.3,5.1c-0.9,24.2,10.8,26,13.7,26
			C34.9,107.2,23.6,112.2,23.6,112.2z M95.7,167.9c-26.3-21.8-44.4-20.7-61.6-54.2c0,0,5-2.3,6-1.3c6.8,7,14.4,13.6,22.5,19.4
			c12.3,8.8,16.8,4.8,16.8,4.8c-4.1-0.9-8.3-2.8-11.9-4.7c-4.7-2.6-8.4-5.3-9.5-6.3c-7.5-6.9-10.9-10.8-13.5-14.7l2.5-0.3
			c9,10.6,15.4,16.8,21.2,20.1c4,2.3,7.7,3.2,11.8,3.4c5.9,0.3,9.8-5.9,9.8-5.9s-1.4,34.5,3.6,34.7c2,0.1-1.1-17.4,2.5-18.9
			L95.7,167.9z M56.6,102.6c0,0,25.7,4.4,31.1,4.6c0,0-0.1,18.4-5,20c-9.7,3.2-23-7.4-28.5-16.1c0,0,6.8,0.3,7.1-0.3L56.6,102.6z
			 M112.3,139c-5.3-0.2-6.6-6.4-5.6-4.8c4.7,6.7,22.7-1.3,23.2-1.6C129.5,132.8,116.9,139.2,112.3,139z M113.3,128.4
			c-3.9-3.4-4.7-19.2-4-20.5l14.7-0.1c0,0-0.1,8,0.2,12.3c0.1,2.2,6.2,3.9,6.8,4.2C130.9,124.3,118,132.4,113.3,128.4z M134.1,119.4
			c-3.4-0.8-4.8-7.7,4.8-1.8c0,0,2-1.4,1.8-6.2c0,0,5.5,2.9,6,2c2.4-3.8-25-9.3-37.5-8.5c-14.7,1-38.8-1.3-58.4-6.9l0.4-2.3
			c22.9,7.5,54.6,7.5,52.6,6.6c-8.7-3.8-15.3,2.9-52.4-8.8c0,0,2.2-5.7,1.9-11.3c-0.3-4.7-0.3-4.7-0.9-6.7
			c17.9,5.6,54.3,7.7,84.3,17.8c6.4,2.2,9.7,7.6,10.4,12.9c0.2,1.7,0.2-13.3,0.8-12.3c1.8,3.3-0.1,17.4,5.1,22.8
			C153.1,117,144.9,121.9,134.1,119.4z M153.3,86.3c-0.2-0.7,1.3-2.3,1.6-1.7c1.1,2,4,11.3,4.2,11.7C158.9,96,153.8,88.3,153.3,86.3
			z"
          />
          <path
            className="st0"
            id="c"
            d="M74.9,35.9c-0.8-2,7-3.6,7.7-4.9c0.5-1-5.7,0.2-9.5,2.8C72,34.6,75.4,37.1,74.9,35.9z"
          />
          <path
            className="st0"
            id="d"
            d="M107,46.6c0-0.1-0.1-0.1-0.1-0.2l0,0.2C106.9,46.6,106.9,46.6,107,46.6z"
          />
          <path
            className="st0"
            id="e"
            d="M29.3,68.7c0.3-1.1,0.4-2.3,0.6-3.4c-0.6,1-1.3,2.1-1.9,3.1c-0.9,1.4-1,1.5-1.4,2.3c0.6-0.1,1.3-0.3,2-0.6
			C28.9,69.9,29.2,69.2,29.3,68.7z"
          />
          <path
            className="st0"
            id="f"
            d="M37,53.6c-0.1-0.3-0.1-0.7-0.2-1c-1.8,3.3-4,7-6.4,10.9c0.2,0.2,0.3,0.4,0.4,0.6c0.5,1,1,2,1.6,2.9
			c0.7,1.1,1.6,0.7,2.6,0.3c0.8-0.4,1.7-0.5,2.5-0.8c0.3-0.1,0.6-0.2,1-0.3c-0.2,0.4-0.3,0.8-0.6,1.1c-0.9,1.2-1.8,2.3-2.6,3.5
			c-0.2,0.3-0.1,0.8,0,1.1c0.1,0.2,0.2,0.3,0.3,0.5c5.5-7.1,10-9.8,10.1-9.8c1-0.5,1.9-0.9,2.8-1.3c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.2-0.1-0.3-0.5-0.2-0.8c0-0.2,0.4-0.3,0.6-0.4c1.1-0.6,2-1.2,1.4-2.4c-0.1-1.9-0.3-3.4-0.4-5c0-0.2,0.2-0.5,0.4-0.7
			c0.1,0.1,0.2,0.3,0.3,0.4c1,1.4,1.9,2.9,3,4.3c0.2,0.3,1,0.3,1.4,0.2c1.7-0.3,3.4-0.6,5.1-1c0,0.2,0.1,0.5,0,0.6
			c-0.1,0.2-0.4,0.3-0.6,0.4c-0.8,0.4-1.9,0.6-2.4,1.7c2.2-0.2,4.5-0.2,6.8-0.2c4.8,0,10.1,0.9,16.5-0.7c-3.9-1.6-13.2-5.8-13.4-9.1
			c-0.1-2.3,0.3-8.5,0.8-12.8c-0.5-0.2-1.1-0.3-1.5-0.4c-0.7-0.2-1.4-0.3-2.1-0.4l-0.2-0.3c0.3-0.3,0.6-0.5,0.9-0.7
			c1-0.7,2-1.4,2.9-2.1c0.1-0.1-0.2-0.8-0.3-1.2c-0.5-1.3-1-2.6-1.5-3.9l0-0.2l0.3,0.1c0.8,0.8,1.6,1.6,2.4,2.5
			c1.1,1.3,1.3,1.4,2.4,0c0.2-0.2,0.4-0.3,0.6-0.4c0.1,0.2,0.2,0.3,0.3,0.5c4.3-2.6,8.2-3.2,9.7-3.3c-0.1-0.6-0.2-1.5-0.2-1.9
			c-0.1-2.9,0.9-5.7,2.9-8.1c-0.9-0.9-1.8-1.9-2.7-2.8L80.8,13c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0,0,0.1,0c-1.2-0.8-2.3-1.7-3.3-2.8
			c-1.4,0.4-2.8,0.8-4.3,1.1c0,0-0.1,0-0.1,0.1c0,0,0.2,0.9,0.5,1.2c0.6,0.8,1.4,1.4,2,2.2c0.1,0.2,0,0.5,0.1,0.7
			c-0.4-0.1-0.8-0.1-1.2-0.2c-1.2-0.2-2.5-0.8-3.6-0.6c-0.7,0.1-1.5,1.2-1.9,2.1c-0.4,0.9-0.4,2-0.7,2.9l-0.2,0.2
			c-0.2-0.1-0.4-0.2-0.4-0.4c-0.3-1.1-0.5-2.1-0.8-3.2c-0.2-0.7-0.3-1.5-0.7-2.2c-0.1-0.3-0.8-0.3-1.2-0.4c-0.9-0.1-1.9-0.2-2.8-0.3
			c-0.1,0-0.1,0-0.2-0.1c-3.6,0.5-7.3,0.7-11,0.7c-4.7,0-9.3-0.4-13.9-1.5c-0.4,0-1.3,1-2.3,2.2c0.2-0.1,0.4-0.1,0.6-0.2
			c1-0.3,2-0.6,3-0.9c0.1,0,0.2,0,0.5,0.1c-0.9,1-1.5,2.2-2.4,2.8c-1,0.7-0.5,1.1-0.2,1.7c0.3,0.4,0.7,0.7,1,1
			c0.1,0.2,0.2,0.4,0.3,0.6c-0.2,0-0.5,0-0.7,0c-1.6-0.2-3.3-0.4-4.9-0.6c-0.2,0-0.3,0-0.5,0c-0.2,0.3-0.4,0.5-0.6,0.7
			c0.2,0.2,0.5,0.4,0.8,0.6c1.6,1.1,4.6,3.3,6.8,7.5c0.4,0.3,0.8,0.5,1.2,0.8c0.4,0.2,0.6,0.6,0.9,0.9c0.3,0.3,0.6,0.5,0.9,0.8
			c0.2,0.6,0.4,1.3,0.6,2c0-0.1,0-0.2,0.1-0.2c0.2-0.4,0.3-0.7,0.3-1c0.8,0.7,1.7,1.4,2.5,2.1c0.6,0.6,1.2,1.2,1.8,1.9l0,0.3
			c-0.2-0.1-0.4,0-0.5-0.2c-1.4-1.5-2.8-2.7-5.1-2c-0.2,0.1-0.5,0.2-0.7,0.2c0.5,2.4,0.8,5.2,0.5,8.6c-0.2,2.4-1.1,5.2-2.6,8.3
			c0.6,1.1,1.5,2.1,2.2,3.1c0.1,0.2,0.2,0.5,0.3,0.7c-0.7-0.4-1.3-0.7-2-1.1c-0.2-0.1-0.4-0.1-0.5-0.2c0,0.2,0.1,0.4,0.1,0.5
			c0.1,1.1,0.1,2.1,0.2,3.2c-0.1,0-0.4,0-0.4-0.1C37.6,55.4,37.1,54.5,37,53.6z M58.5,47.6c0.1-0.3,0.1-0.6,0.2-0.9l-0.2,0
			c0-0.2,0.1-0.5,0.1-0.7c0.1,0.2,0.3,0.3,0.4,0.5c1,1.1,1.8,2.3,3,3.1c0.9,0.6,2.2,0.8,3.3,1.1c0.6,0.1,1.2,0.1,1.8,0.2
			c0.1,0,0.3,0.2,0.4,0.3c-0.3,0.2-0.6,0.5-0.9,0.7c-0.5,0.2-1.1,0.4-1.7,0.6c0.1,0.3,0.2,0.7,0.5,1c0.7,0.8,1.4,1.4,2,2.2
			c0.1,0.1,0,0.3,0,0.4c-0.1,0-0.3,0-0.4-0.1c-1.3-1.2-2.7-2.4-4-3.6c-0.2-0.2-0.6-0.2-0.8-0.4c-1.3-1.2-2.6-2.4-3.8-3.6
			c-0.5-0.5-1.1-0.9-1.6-1.4c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2,0,0.3,0.1c0.3,0.2,0.5,0.4,0.8,0.6C58,47.4,58.3,47.5,58.5,47.6z
			 M43.9,26.1l-0.1-0.2c0.1-0.1,0.3-0.1,0.4-0.2c1.5-0.5,3.1-0.9,4.5-1.7c0.4-0.2,0.4-1.4,0.4-2.1c0-0.4-0.1-0.8-0.2-1.2l-0.1,0
			c0-0.6,0-1.2,0-1.8c0.4,0.4,0.9,0.9,1.2,1.3c0.6,0.7,1,2,1.6,2c0.8,0.1,1.8-0.7,2.7-1.1c0.4-0.2,0.7-0.6,1-0.9
			c0.1-0.1,0.3,0,0.4,0c-0.1,0.4-0.1,0.9-0.3,1.3c-0.3,1.2-1.1,2.4-0.9,3.5c0.2,1.1,1.2,2.1,1.9,3.1c0.3,0.4,0.6,0.7,0.9,1
			c0.1,0.1,0,0.3,0,0.4c-0.4-0.1-0.8-0.1-1.2-0.2c-1.2-0.2-2.4-0.6-3.5-0.7c-0.6,0-1.2,0.6-1.7,1c-0.8,0.6-1.5,1.2-2.3,1.8l-0.4,0
			c-0.1-0.1-0.1-0.2-0.1-0.4c0.1-0.8,0.1-1.6,0.3-2.3c0.6-1.7-0.9-1.3-1.6-1.6C45.9,26.6,44.9,26.4,43.9,26.1z"
          />
          <path
            className="st0"
            id="g"
            d="M34.6,75.1c-0.4-0.2-0.9-0.4-1.3-0.5c-0.3-0.1-1,0.1-1.2,0.4c-0.8,0.8-1.6,1.8-2.4,2.7
			c-0.4,0.4-0.8,0.8-1.2,1.2l-0.3-0.2c0.1-0.9,0.2-1.8,0.5-2.7c0.4-1,0.3-3.1-0.4-3.5c-0.7-0.3-1.3-0.6-2-0.8
			c-2.9,4.7-5.8,9.5-8.4,14.3c0.2,0.4,0.4,0.9,0.6,1.3c0.5,1.2,1.3,0.8,2.1,0.7c1.4-0.2,2.7-0.4,4.1-0.6c0.2,0,0.4,0.1,0.7,0.1
			c-0.1,0.3-0.2,0.6-0.3,0.8c-1.3,1-2.7,1.9-3.9,3.1c-0.3,0.3,0.3,1.4,0.6,2.1c0.5,1,1.1,2,1.6,3l-0.1,0.4c-0.2,0-0.4,0-0.5-0.1
			c-1.5-1.1-2.9-2.2-4.4-3.3c-0.3-0.2-0.8-0.3-1.2-0.4c-0.8-0.1-1.7,0-2.5-0.1c-0.8,1.8-1.5,3.7-2,5.5c0.5,0.2,1.1,0.4,1.6,0.6
			c1.9,0.9,3.6,2.4,5.5,3.4c0.7,0.4,1.5,0.8,2.3,1.2C25.3,90.8,30.1,81.5,34.6,75.1z"
          />
        </g>
      </g>
      <g id="Red">
        <path
          className="st1"
          id="h"
          d="M186.9,103.9c-0.4,0.1-0.9,0.1-1.3,0.2c-1.7,0.6-3.3,1.3-4.9,1.9c-1,0.4-2,0.9-3.1,1.1c-1.6,0.4-3.1,0.8-4.7,1
		c1.1,4.9,1.8,10,2.1,15.4l0,0.2c0,0.5-0.2,0.7-0.3,0.8c-0.3,0.2-0.6,0.2-0.9,0.1c-4-1.4-6.7-2.3-7.8-2.6c-2,8.5-6.7,15-12.7,20.6
		c1.1,0,2.2,0.1,3.3,0.1c2.7-0.1,5.4-0.2,8-0.5c1.7-0.2,3.3-0.8,4.9-1.3c2.3-0.7,4.6-1.5,6.9-2.3c1.6-0.5,3.1-1.2,4.7-1.7
		c4.1-7.2,6.4-16.1,6.4-27.4C187.5,107.7,187.3,105.8,186.9,103.9z"
        />
        <path
          className="st1"
          id="i"
          d="M84.4,11.6C84.4,11.6,84.4,11.6,84.4,11.6c2.2,0.3,4.4,0.1,6.5-0.7c0.6-0.2,1.3-0.2,1.9-0.3
		c2.7-0.6,5.4-1.2,8.1-1.8c1.9-0.4,3.8-0.9,5.6-1.5c1.2-0.4,2.4-0.9,3.5-1.4c-4.4-1.5-8-3.1-10.6-4.2c-0.1-0.1-0.3-0.1-0.3-0.2
		c-1.4,0.7-8.7,4-18.7,6.8C81.6,9.6,83,10.5,84.4,11.6z"
        />
        <path
          className="st1"
          id="j"
          d="M72.4,157.2c-14.3-7.9-25.2-16.6-32.8-26c-2-0.9-4-1.8-6-2.7c-1.9-0.8-3.7-1.8-5.7-2.5c-1.4-0.5-3-0.4-4.5-0.8
		c-2.8-0.8-5.5,0.3-8.2,0.6c-0.8,0.1-1.6,0.4-2.3,0.9c3.7,13.3,12,22,22.9,29.5c0,0,0,0,0,0c1.5-0.2,3,0.4,4.6,0.6
		c1,0.1,2,0.2,3,0.3c0.8,0.1,1.6,0.4,2.4,0.6c1.1,0.3,2.3,0.6,3.4,0.9c1.7,0.4,3.4,0.7,5.1,1c2.3,0.3,4.7,0.7,7,0.6
		c3.3,0,6.6-0.3,9.8-0.7c1.3-0.1,2.5-0.4,3.7-0.6C74.1,158.2,73.3,157.7,72.4,157.2z"
        />
        <path
          className="st1"
          id="k"
          d="M131.6,35.3c0,0.4-0.3,0.7-0.5,0.8c-1.3,1.1-7.8,3-13.6,4.4c1.1,0.8,2.1,1.5,3.2,2.3c2.1,1.5,4,3.3,6.2,4.6
		c1,0.6,2.4,0.6,3.6,0.6c2.1-0.1,4.2-0.5,6.3-0.9c1.8-0.4,3.7-0.9,5.5-1.4c1.3-0.4,2.5-0.8,3.8-1.2c2.4-0.7,4.8-1.3,7.2-2
		c1.1-0.3,2.2-0.7,3.3-1C156,28,163.1,23,165.9,21c0.3-0.2,0.6-0.4,0.8-0.6c-0.5-0.6-1.2-1.5-1.9-2.5c-0.9-1.2-2.6-3.5-3.6-4.4
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-2,0.4-4,0.6-5.9,1.2c-2.1,0.6-4.1,1.6-6.2,2.4c-1,0.4-2.1,0.7-3.1,1.2
		c-1,0.4-1.9,1-2.8,1.5c-1,0.5-2,0.9-3,1.3c-1.9,0.7-3.8,1.4-5.7,2.1c-0.2,0.1-0.4,0.1-0.6,0.1c-0.9,0.2-1.7,0.4-2.6,0.6
		C130.6,27.4,131.6,33,131.6,35.3z"
        />
        <path
          className="st1"
          id="l"
          d="M166.6,66.4c-0.9-1.4-1.8-2.7-2.7-4c-1.6,0.6-3.1,1.1-4.7,1.7c-0.3,0.1-0.6,0.1-0.9,0.2
		c-1.6,0.5-3.2,1.1-4.8,1.4c-1.2,0.2-2.5,0.2-3.7,0c-0.8-0.2-2.3-1-3-1.6c-0.7-0.6-1.4-1.3-2.1-1.9c-1-0.9-1.9-1.8-3-2.5
		c-1.5-1-2.1-1.8-3.7-2.7c-0.4-0.2-1-0.2-1.4-0.1c0,0,0,0,0,0c0.2,4.2,1.5,7.8,2.3,10.3c0.5,1.5,0.9,3.6,0.9,4.4
		c0,2.5-5.4,4.2-17.9,5.2c0.2,1.4,0.4,2.7,0.6,4.1c2.3,0.2,4.9,0.4,7.4,0.6c9.8,0.9,13.2,0.6,15.3,1.2c0,0,0.1,0,0.1-0.1
		c0.2-0.1,0.5-0.2,0.7-0.3l1.2-12.8c0.1-0.2,0.3-0.7,0.8-0.9c0.5-0.1,2.1,0.1,2.8,0.7c2.9,2.3,5.4,5,7.8,7.9
		c0.8-0.3,1.6-0.6,2.4-0.7c2-0.1,4,0.5,5.4,2.2c0.5,0.5,1,1,1.6,1.5c1.4,1.2,3.1,2.3,4.3,3.7c0.9,1.1,1.8,0.9,2.8,0.7
		c0.8-0.2,1.7-0.4,2.5-0.6C174.4,78.1,170.4,72,166.6,66.4z"
        />
        <path
          className="st1"
          id="m"
          d="M116.9,177.1c-2.5,0.5-4.9,1.1-7.3,1.7c-1.3,0.3-2.6,0.7-3.9,1.1c-1.9,2.4-3.5,5-4.8,7.8l-0.1,0.3l-0.4,0.1
		l0,0c-0.7,0-2-1.2-2.4-1.8c-0.4-0.6-1-1.6-1.7-2.7c-0.2-0.4-0.4-0.7-0.7-1.1c-1.4,0.4-2.8,0.8-4.2,1.2c-0.9,0.3-1.9,0.5-2.8,0.7
		c-1.1,0.3-2.3,0.5-3.4,0.8c7.9,5.8,12.5,11.2,14.1,13.3c2.1-2.7,9-10.8,21.4-18.2c4-2.4,7.9-4.5,11.8-6.6c-1.1,0.2-2.2,0.6-3.3,1.1
		C125.2,176.5,120.9,176.3,116.9,177.1z"
        />
        <g>
          <path
            className="st1"
            id="n"
            d="M82.3,125.9c1.3-0.4,2.2-2.1,2.8-4.3c-1.9,0.4-3.8,0.8-5.6,1.3c-1.9,0.5-3.8,1.1-5.7,1.7
			c-0.4,0.1-0.9,0.2-1.4,0.4C75.8,126.4,79.3,126.9,82.3,125.9z"
          />
          <path
            className="st1"
            id="o"
            d="M122.8,120.3c-0.1-1.5-0.1-3.4-0.2-5.2c-2.5,0.1-4.1,0.7-6.6,1c-1.4,0.2-2.8,0.6-4.1,1.2
			c-0.4,0.2-0.9,0.4-1.3,0.5c0.5,4.1,1.6,8.4,3.4,9.9c4.2,3.6,14.3-3.1,14.3-3.1C127.8,124.3,122.9,122.3,122.8,120.3z"
          />
        </g>
      </g>
    </StyledLogo>
  );
};

export default LogoNoTextSVG;
