import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledLinks = styled.div`
  /* visibility: ${props => (props.isOpen ? 'visible' : 'hidden')}; */
  text-align: right;
  padding: 10px;
  color: white;
  position: fixed;
  top: 5.2rem;
  right: 0.8rem;
  font-size: 1.2em;
  z-index: ${props => (props.isOpen ? 12 : -1)};
  display: ${props => (props.isReady ? 'flex' : 'none')};
  flex-direction: column;
  a {
    transform: ${props =>
      props.isOpen ? 'translateY(0)' : 'translateY(-400px)'};
    font-size: 0.8em;
    color: ${props => (props.dark ? props.theme.navy() : props.theme.white())};
    font-weight: 200;
    letter-spacing: 6px;
    margin: 5px 0;
    opacity: ${props => (props.isOpen ? 0.9 : 0)};
    transition: all 0.3s;
    text-underline-offset: 7px;
    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
    ${props => props.animationDelayCSS}
    @media (max-width: ${props => props.theme.mobileBreakPoint}) {
      font-weight: 700;
    }
  }
`;

const Navbar = ({ isOpen, getReady, dark, children }) => {
  // getReady turns to true when the icon that opens this navbar is hovered. That way, the links display becomes visible, but they are still at opacity-0 until the icon is clicked. The drop down animation won't work if the links got from display: none to display: flex instantly.
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (getReady) {
      setIsReady(true);
    }
  }, [getReady]);

  const getAnimationDelayCSS = () => {
    const array = [];
    for (let i = 0; i < children.length; i++) {
      array.push(`&:nth-child(${i + 1}) {
        transition-delay: ${0.05 * i}s;
        transition-property: transform;
      };`);
    }
    array.join('');
    return array.join('');
  };
  const animationDelayCSS = getAnimationDelayCSS();
  return (
    <StyledLinks
      icon
      isReady={isReady}
      isOpen={isOpen}
      animationDelayCSS={animationDelayCSS}
      dark={dark}
    >
      {children}
    </StyledLinks>
  );
};

export default Navbar;
