import styled from 'styled-components';

const SimpleButton = styled.button`
  width: min-content;
  white-space: nowrap;
  min-height: 40px;
  max-width: ${props => props.maxWidth};
  border: none;
  border-radius: 4px;
  color: ${props => props.theme.navy()};
  border-color: ${props => props.theme.navy()};
  border-width: 2px;
  border-style: solid;
  background: transparent;
  transition: 0.9s all;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: ${props => (props.tight ? '1px' : '3px')};
  font-size: 0.8em;
  padding: 4px 10px;
  outline: none;
  &:hover {
    color: ${props => props.theme.red()};
    border-color: ${props => props.theme.red()};
  }
`;

export default SimpleButton;
