import React from 'react';
import styled from 'styled-components';
import StyledButton from '../styles/StyledButton';

const StyledCookieBanner = styled.div`
  width: 100vw;
  min-height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${props => props.colorOne || props.theme.navy()};
  color: ${props => props.colorTwo || props.theme.white()};
  display: flex;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    display: grid;
  }
  align-items: center;
  justify-content: space-between;
  padding: 2vh 5vw;
  a {
    margin: 0 18px;
  }
  button {
    justify-self: flex-end;
  }
  z-index: 9;
`;

const CookieBanner = ({ colorOne, colorTwo, handleClick }) => {
  return (
    <StyledCookieBanner colorOne={colorOne} colorTwo={colorTwo}>
      <p>
        This website uses cookies to ensure you get the best experience.
        <a href="https://www.cookiesandyou.com/" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
      </p>
      <StyledButton width="200px" colorOne="red" colorTwo="navy" onClick={handleClick}>
        Got It
      </StyledButton>
    </StyledCookieBanner>
  );
};

export default CookieBanner;
