import React, { useState } from 'react';
import styled from 'styled-components';

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props =>
    props.colorOne ? props.theme[props.colorOne](0.8) : props.theme.navy(0.95)};
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 99999999;
  .contents {
    border-radius: 6px;
    position: relative;
    background-color: ${props =>
      props.colorTwo
        ? props.theme[props.colorTwo](0.9)
        : props.theme.white(0.9)};
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  color: ${props =>
    props.color ? props.theme[props.color]() : props.theme.white()};
  font-size: 3rem;
  border: 0;
  position: absolute;
  z-index: 2;
  right: -6rem;
  top: -6.5rem;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${props => props.theme.navy(0.9)};
  }
  transition: color 0.4s;
  @media (max-width: 400px) {
    visibility: hidden;
  }
`;

const Modal = ({
  open,
  children,
  handleClose,
  colorOne,
  colorTwo,
  noCloseButton,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      {open && (
        <StyledModal
          onClick={() => {
            if (!hovered) handleClose();
          }}
          colorOne={colorOne}
          colorTwo={colorTwo}
        >
          <div
            className="contents"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {children}
            {!noCloseButton && (
              <CloseButton
                onClick={handleClose}
                color={colorTwo}
                data-test="closeButton"
              >
                x
              </CloseButton>
            )}
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default Modal;
