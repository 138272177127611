import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useCookies } from 'react-cookie';
import CookieBanner from './FunStuff/CookieBanner';
import AgeGate from './FunStuff/AgeGate';
import Header from './Header';
import SteakNightPopup from './SteakNightPopup';

export const themeStyles = {
  navy: function(opacity = 1) {
    return `rgba(19, 20, 61, ${opacity})`;
  },
  lightgrayblue: function(opacity = 1) {
    return `rgba(121, 161, 184, ${opacity})`;
  },
  medgrayblue: function(opacity = 1) {
    return `rgba(50, 113, 150, ${opacity})`;
  },
  red: function(opacity = 1) {
    return `rgba(168, 8, 8, ${opacity})`;
  },
  gray: function(opacity = 1) {
    return `rgba(0, 0, 66, ${opacity})`;
  },
  darkgray: function(opacity = 1) {
    return `rgba(12, 18, 20, ${opacity})`;
  },
  white: function(opacity = 1) {
    return `rgba(236, 237, 247, ${opacity})`;
  },
  transparent: function() {
    return 'transparent';
  },
  maroon: function(opacity = 1) {
    return `rgba(92, 5, 5, ${opacity})`;
  },
  darkestBrown: function(opacity = 1) {
    return `rgba(15, 0, 0, ${opacity})`;
  },
  mobileBreakPoint: '936px',
  headerHeight: '50px',
  minHeight: `calc(100vh - 75px)`,
};

const GlobalStyle = createGlobalStyle`
  html::-webkit-scrollbar  {
    display: none; 
  }
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    background: ${props => props.color || 'white'};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-family: 'Catamaran', sans-serif;
    font-size: 1.6rem;
  } 
  a {
    text-decoration: none;
    letter-spacing: 5px;
    color: ${themeStyles.navy};
    cursor: pointer;
    &:hover{
      color: ${themeStyles.colorThree}
    };
  } 
  h1, h2, h3, h4, button {
    font-family: 'Catamaran', sans-serif;
    text-transform: uppercase;
    letter-spacing: .25em;
  } 
  h1 {
    letter-spacing: .2em;
    font-size: 6vw;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.9;
    margin: 2vw;
    @media(max-width: 400px) {
      font-size: 44px;
    }
  }
  p {
    letter-spacing: 1.5px;
  }
  div::-webkit-scrollbar {
      display: none; 
  }
  .contentSide {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
    padding: 0 7vw;
    p {
      line-height: 1.8em;
    }
  }
`;

const WhiteBar = styled.div`
  height: 10px;
  width: 100vw;
  position: fixed;
  top: ${props => props.top && 0};
  bottom: ${props => props.bottom && 0};
  left: 0;
  background: white;
  z-index: 10;
`;

const Page = props => {
  const [cookies, setCookie] = useCookies(['over21', 'acknowledgeCookies']);
  const [ready, setReady] = useState(false);
  const [isSteakNightPopupVisible, setIsSteakNightPopupVisible] = useState(
    false
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.document.documentMode) return;
      else setReady(true);
    }
  });

  useEffect(() => {
    if (cookies.acknowledgeSteakNight) {
      setIsSteakNightPopupVisible(false);
    } else if (cookies.acknowledgeCookies && cookies.over21) {
      setTimeout(() => setIsSteakNightPopupVisible(true), 2000);
    }
  }, [cookies]);

  return (
    <ThemeProvider theme={themeStyles}>
      <div className="pageWrapper">
        {!props.noWhiteBar && <WhiteBar top />}
        <GlobalStyle color={props.color} />
        <Header
          lightBackground={props.lightBackground}
          noHeader={props.noHeader}
        />
        {props.children}
        {ready && !cookies.acknowledgeCookies && (
          <CookieBanner
            colorOne={themeStyles.colorOne}
            handleClick={() => {
              setCookie('acknowledgeCookies', true, {
                maxAge: 3600000,
              });
            }}
          />
        )}
        {ready && !cookies.over21 && (
          <>
            <AgeGate
              handleClick={() => {
                setCookie('over21', true, { maxAge: 3600000 });
              }}
            />
          </>
        )}
        {isSteakNightPopupVisible && props.imgData && (
          <SteakNightPopup
            onClose={() => {
              setIsSteakNightPopupVisible(false);
              setCookie('acknowledgeSteakNight', true, { maxAge: 3600000 });
            }}
            imgData={props.imgData}
          />
        )}
        {!props.noWhiteBar && <WhiteBar bottom />}
      </div>
    </ThemeProvider>
  );
};

export default Page;
