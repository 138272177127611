import React from 'react';
import styled from 'styled-components';

const StyledHeaderText = styled.svg`
  width: 20vw;
  path {
    fill: #13143d;
  }
`;

const LibertyCallTextSVG = () => (
  <StyledHeaderText
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 413.61 28.87"
    className="textHeader"
  >
    <defs />
    <title>libertyCallHeader</title>
    <path
      className="cls-1"
      d="M12,26.53c5.52,0,8.5-2.43,11.36-6l.78.51-3.84,7.2H.57v-1c3.13-.2,3.8-.44,3.8-3.41V5.26c0-3-.67-3.21-3.8-3.41v-1H12v1c-3.13.2-3.79.4-3.79,3.41v20.1a1.07,1.07,0,0,0,1.17,1.17Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M37.35,28.29v-1c3.13-.2,3.8-.44,3.8-3.41V5.26c0-3-.67-3.21-3.8-3.41v-1H48.83v1c-3.14.2-3.8.4-3.8,3.41V23.91c0,3,.66,3.21,3.8,3.41v1Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M76.44,28.29H62.93v-1c3.13-.2,3.8-.44,3.8-3.41V5.26c0-3-.67-3.21-3.8-3.41v-1H75.78c6.46,0,11,1.68,11,6.77,0,3.21-2.19,5.17-6.07,5.92,4.39.86,6.86,3.56,6.86,6.93C87.61,25.59,83,28.29,76.44,28.29ZM75.89,2.64H71.78a1.07,1.07,0,0,0-1.17,1.17V12.7h5.52c4,0,6.38-1.44,6.38-5S80.32,2.64,75.89,2.64Zm1,11.79h-6.3V25.36a1.07,1.07,0,0,0,1.17,1.17h4.66c4.7,0,6.86-2.43,6.86-6S80.91,14.43,76.91,14.43Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M115.18,26.53c5.92,0,8.9-2.43,11.75-6l.83.51-3.84,7.2H102.26v-1c3.13-.2,3.8-.44,3.8-3.41V5.26c0-3-.67-3.21-3.8-3.41v-1H124l2.62,6.38-.82.43c-2.47-3.33-5.25-5.05-10.26-5.05h-4.39a1.07,1.07,0,0,0-1.17,1.17V13.1h6.34c2.9,0,3.45-1.3,3.68-3.8h1v9.36h-1c-.23-2.51-.78-3.8-3.68-3.8h-6.34v10.5a1.07,1.07,0,0,0,1.17,1.17Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M156.67,14.7,164,25c.94,1.3,2,2.16,4.54,2.36v1h-6.69l-9.52-13.47h-3.68v9.09c0,3,.66,3.21,3.8,3.41v1H141v-1c3.13-.2,3.8-.44,3.8-3.41V5.26c0-3-.47-3.21-3.8-3.41v-1h13.59c6.5,0,11.09,1.88,11.09,7C165.64,12.35,161.84,14.39,156.67,14.7ZM154.55,2.64h-4.74a1.07,1.07,0,0,0-1.17,1.17V13.1h5.87c4.39,0,6.82-1.65,6.82-5.25S158.9,2.64,154.55,2.64Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M190.82,3.81a1.07,1.07,0,0,0-1.17-1.17c-4.27,0-6.54,1.06-9.05,5.05l-.86-.43,3-7.25h1a1.15,1.15,0,0,0,1.18.87h15.94A1.15,1.15,0,0,0,202,0h1l3,7.25-.82.43c-2.51-4-5-5.05-9.32-5.05a1.07,1.07,0,0,0-1.18,1.17v20.1c0,3,.67,3.21,3.8,3.41v1H187v-1c3.14-.2,3.8-.44,3.8-3.41Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M228.19,23.91v-7l-7.13-11.6c-1.41-2.31-1.91-3.21-3.87-3.41v-1h10.3v1c-2.7.2-3.41,1.1-2,3.37l5.72,9.44,5.45-9.4c1.33-2.31.58-3.21-2.16-3.41v-1h8.42v1c-2.19.2-2.82,1.1-4.15,3.41l-6.66,11.52v7.13c0,3,.67,3.21,3.8,3.41v1H224.39v-1C227.53,27.12,228.19,26.88,228.19,23.91Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M301.6,8.51l-.9.32C298.31,4.48,295,2,290.75,2c-6.23,0-9,5.29-9,12.61s2.74,12.58,9,12.58c4.27,0,8.19-2.35,9.44-6.46l1.76.58c-1.76,4.78-5.44,7.6-11.43,7.6-7.68,0-13.09-6-13.09-14.3S282.84.29,290.52.29a11.75,11.75,0,0,1,6.93,2L298,.88h1.13Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M329.18.88l9,23.58c.66,1.84,1.45,2.66,3.29,2.86v1H331.25v-1c2.94-.2,3.61-.79,3-2.43l-2-5H321.62l-1.88,5c-.59,1.49.31,2.23,3.33,2.43v1h-8.7v-1a3.66,3.66,0,0,0,3.53-2.67L327,.88Zm-6.89,17.27h9.32l-4.7-12.26Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M365.25,26.53c5.53,0,8.5-2.43,11.36-6l.79.51-3.84,7.2h-19.7v-1c3.13-.2,3.8-.44,3.8-3.41V5.26c0-3-.67-3.21-3.8-3.41v-1h11.47v1c-3.13.2-3.8.4-3.8,3.41v20.1a1.07,1.07,0,0,0,1.18,1.17Z"
      transform="translate(-0.57 -0.01)"
    />
    <path
      className="cls-1"
      d="M402,26.53c5.52,0,8.5-2.43,11.36-6l.78.51-3.84,7.2h-19.7v-1c3.13-.2,3.8-.44,3.8-3.41V5.26c0-3-.67-3.21-3.8-3.41v-1h11.48v1c-3.14.2-3.8.4-3.8,3.41v20.1a1.07,1.07,0,0,0,1.17,1.17Z"
      transform="translate(-0.57 -0.01)"
    />
  </StyledHeaderText>
);

export default LibertyCallTextSVG;
