import styled, { keyframes, css } from 'styled-components';

const finishLeftToRight = keyframes`
0% {
  transform: translateX(0vw);
  opacity: 1;
}
100% {
  transform: translateX(100vw);
  opacity: 1;
}
`;

const finishRightToLeft = keyframes`
0% {
  transform: translateX(0);
  opacity: 1;
}
100% {
  transform: translateX(-100vw);
  opacity: 1;
}
`;

const startFromHalfwayLeftToRight = keyframes`
0% {
  opacity: 0;
  transform: translateX(-50vw);
}
75% {
  opacity: 1;
  transform: translateX(-50vw);
}
100% {
  transform: translateX(0vw);
}
}`;

const startFromHalfwayRightToLeft = keyframes`
0% {
  opacity: 0;
  transform: translateX(50vw);
}
75% {
  opacity: 1;
  transform: translateX(50vw);
}
100% {
  transform: translateX(0vw);
}
}`;

const startLeftToRight = keyframes`
0% {
  opacity: 0;
  transform: translateX(-100vw);
}
100% {
  opacity: 1;
  transform: translateX(0vw);
}
}`;

const startRightToLeft = keyframes`
0% {
  opacity: 0;
  transform: translateX(100vw);
}
100% {
  opacity: 1;
  transform: translateX(0vw);
}
}`;

const animationList = {
  startFromHalfwayLeftToRight: css`
    animation: ${startFromHalfwayLeftToRight} 0.8s linear;
  `,
  startFromHalfwayRightToLeft: css`
    animation: ${startFromHalfwayRightToLeft} 0.8s linear;
  `,
  finishRightToLeft: css`
    animation: ${finishRightToLeft} 0.4s linear;
    opacity: 0;
  `,
  finishLeftToRight: css`
    animation: ${finishLeftToRight} 0.4s linear;
    opacity: 0;
  `,
};

const mobileAnimationList = {
  /* Don't use halfway animations on mobile. All grids are just 1 column. 
     Becasue of this, no need to fade in, so use a quicker animation */
  startFromHalfwayLeftToRight: css`
    animation: ${startLeftToRight} 0.4s linear;
  `,
  startFromHalfwayRightToLeft: css`
    animation: ${startRightToLeft} 0.4s linear;
  `,
  finishRightToLeft: css`
    animation: ${finishRightToLeft} 0.4s linear;
    opacity: 0;
  `,
  finishLeftToRight: css`
    animation: ${finishLeftToRight} 0.4s linear;
    opacity: 0;
  `,
};

const WipeTransition = styled.div`
  height: 100vh;
  width: 100vw;
  background: white;
  position: fixed;
  top: 0;
  z-index: 12;
  ${props => animationList[props.animation]}
  @media(max-width: ${props => props.theme.mobileBreakPoint}) {
    ${props => mobileAnimationList[props.animation]}
  }
`;

export default WipeTransition;
